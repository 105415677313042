import React, { useState } from 'react';
import { SectionLink } from 'react-scroll-section';
import Chevron from './Chevron';
import ScrollDownIconWrapper from './ScrollDownIconWrapper';

const ScrollDownIcon = () => {
  const [isHover, setIsHover] = useState(false);
  return (
    <SectionLink section="about">
      {({ onClick }) => (
        <ScrollDownIconWrapper
          onMouseEnter={() => { setIsHover(true); }}
          onMouseLeave={() => { setIsHover(false); }}
          onClick={onClick}
        >
          <Chevron isHover={isHover} />
          <Chevron isHover={isHover} />
          <Chevron isHover={isHover} />
        </ScrollDownIconWrapper>
      )}
    </SectionLink>
  );
};

export default ScrollDownIcon;
