import styled from '@emotion/styled';

const ScrollDownIconWrapper = styled.div`
  text-align: center;
  width: 24px;
  height: 50px;
  cursor: pointer;
    position: absolute;
    left: 50%;
    top: 150%;
  
  
    @media (max-width: 768px) {
  visibility: hidden;
  }

`;

export default ScrollDownIconWrapper;
