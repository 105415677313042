import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import ButtonStyled from '../Intro/Button';
import Tick from './Tick';
import Text from './Text';

const EstimateDone = ({
  locales, setIsOpen,
}) => {
  const onGoBackClick = () => {
    setIsOpen(false);
  };

  return (
    <Grid>
      <Row center="xs">
        <Col xs={6} md={4}>
          <Tick style={{ marginBottom: 20 }} />
          <Text color="white">
            {locales.thankYou}
          </Text>
          <Text color="white">
            {locales.contactYouSoon}
          </Text>
          <ButtonStyled
            onClick={onGoBackClick}
            style={{ marginTop: 20 }}
          >
            {locales.button2}
          </ButtonStyled>
        </Col>
      </Row>
    </Grid>
  );
};

EstimateDone.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  locales: PropTypes.shape({
    thankYou: PropTypes.string.isRequired,
    contactYouSoon: PropTypes.string.isRequired,
    button2: PropTypes.string.isRequired,
  }).isRequired,
};


export default EstimateDone;
