import React from 'react';
import Fade from 'react-reveal/Fade';
import Particles from 'react-particles-js';
import { Col, Grid, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import particlesOptions from './particlesOptions';
import ScrollDownIcon from './ScrollDownIcon/ScrollDownIcon';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import SectionStyled from '../../components/SectionStyled';
import Paragraph from './Paragraph';
import Link from './Link';
import Button from './Button';

const Intro = ({ locales, setIsEstimateOpen }) => (
  <SectionStyled
    isIntro
    id="intro"
    background="linear-gradient(180deg, rgba(0,0,30,1) 0%, rgba(0,0,54,1) 100%)"
  >
    <Particles
      width="98vw"
      height="100vh"
      params={particlesOptions}
    />
    <div style={{
      position: 'absolute',
    }}
    >
      <Grid>
        <Fade cascade>
          <SectionTitle>
            {locales.hello}
          </SectionTitle>
        </Fade>
        <Row center="xs">
          <Col md={5} xs={12}>
            <article>
              <Paragraph>
                {locales.text1}
              </Paragraph>
              <Button onClick={() => {
                setIsEstimateOpen(true);
              }}
              >
                {locales.button1}
              </Button>
              <Paragraph>
                {locales.text2}
              </Paragraph>
              <Paragraph>
                {locales.text3}
              </Paragraph>
              <Link href="mailto:gilbertlaskawiec@gmail.com">
                {locales.button2}
              </Link>
              <Paragraph>
                {locales.text4}
              </Paragraph>
              <Link target="_blank" href="https://www.linkedin.com/in/glaskawiec/">
                {locales.button3}
              </Link>
              <Paragraph>
                {'.'}
              </Paragraph>
            </article>
            <ScrollDownIcon />
          </Col>
        </Row>
      </Grid>
    </div>
  </SectionStyled>
);

Intro.propTypes = {
  setIsEstimateOpen: PropTypes.func.isRequired,
  locales: PropTypes.shape({
    hello: PropTypes.string.isRequired,
    text1: PropTypes.string.isRequired,
    text2: PropTypes.string.isRequired,
    text3: PropTypes.string.isRequired,
    text4: PropTypes.string.isRequired,
    button1: PropTypes.string.isRequired,
    button2: PropTypes.string.isRequired,
    button3: PropTypes.string.isRequired,
  }).isRequired,
};

export default Intro;
