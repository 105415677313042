import React, { useState, useContext } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import Select from 'react-select';
import emailjs from 'emailjs-com';
import Input from './Input';
import ButtonStyled from '../Intro/Button';
import Textarea from './Textarea';
import EstimateLocales from './EstimateLocales';
import EstimateDone from './EstimateDone';
import Form from './Form';
import { LangContext } from '../../components/LangContextProvider';
import selectStyles from './selectStyles';
import Overlay from '../../components/header/BurgerButton/Menu/Overlay';

const Estimate = () => {
  const initialState = {
    email: '',
    name: '',
    companyName: '',
    phoneNumber: '',
    service: '',
    budget: '',
    description: '',
  };
  const { lang, isEstimateOpen, setIsEstimateOpen } = useContext(LangContext);
  const locales = EstimateLocales[lang];
  const [isFinished, setIsFinished] = useState(false);
  const [data, setData] = useState(initialState);

  const {
    email,
    name,
    companyName,
    phoneNumber,
    service,
    budget,
    description,
  } = data;

  const sendEmail = () => {
    const templateParams = {
      email,
      name,
      companyName,
      phoneNumber,
      service: service.label,
      budget: budget.label,
      description,
    };

    const serviceId = 'default_service';
    const templateId = 'standard';
    emailjs.send(serviceId, templateId, templateParams);
  };

  const onEstimateClick = () => {
    setIsFinished(true);
    sendEmail();
  };

  const serviceOptions = [
    { value: 'web', label: locales.serviceOptions.web },
    { value: 'mobile', label: locales.serviceOptions.mobile },
  ];

  return (
    <Overlay
      isMenuOpen={isEstimateOpen}
      onTransitionEnd={() => {
        setIsFinished(false);
      }}
    >
      {isFinished
        ? (
          <EstimateDone
            setIsOpen={setIsEstimateOpen}
            setIsFinished={setIsFinished}
            locales={locales}
          />
        ) : (
          <Grid style={{
            height: '100%',

          }}
          >
            <Form id="myForm">
              <Row center="xs">
                <Col>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(event) => {
                      setData({ ...data, name: event.target.value });
                    }}
                    placeholder={locales.name}
                  />
                  <Input
                    type="text"
                    name="companyName"
                    value={companyName}
                    onChange={(event) => {
                      setData({ ...data, companyName: event.target.value });
                    }}
                    placeholder={locales.companyName}
                  />
                </Col>
              </Row>
              <Row center="xs">
                <Col>
                  <Input
                    type="text"
                    name="email"
                    value={email}
                    onChange={(event) => {
                      setData({ ...data, email: event.target.value });
                    }}
                    placeholder={locales.email}
                  />
                  <Input
                    type="text"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(event) => {
                      setData({ ...data, phoneNumber: event.target.value });
                    }}
                    placeholder={locales.phoneNumber}
                  />
                </Col>
              </Row>
              <Row center="xs">
                <Col>
                  <Select
                    styles={selectStyles}
                    options={serviceOptions}
                    value={service}
                    onChange={(selectedOption) => {
                      setData({ ...data, service: selectedOption });
                    }}
                    placeholder={locales.service}
                  />
                  <Input
                    type="text"
                    name="budget"
                    value={phoneNumber}
                    onChange={(event) => {
                      setData({ ...data, budget: event.target.value });
                    }}
                    placeholder={locales.budget}
                  />
                </Col>
              </Row>
              <Row center="xs">
                <Textarea
                  width={660}
                  height={150}
                  type="text"
                  name="description"
                  value={description}
                  onChange={(event) => {
                    setData({ ...data, description: event.target.value });
                  }}
                  placeholder={locales.description}
                />
              </Row>
              <Row center="xs">
                <ButtonStyled
                  onClick={onEstimateClick}
                  style={{ width: 150, marginBottom: 25 }}
                >
                  {locales.button}
                </ButtonStyled>
              </Row>
            </Form>
          </Grid>
        )}
    </Overlay>
  );
};

export default Estimate;
