import styled from '@emotion/styled';

const Link = styled.a`
color: white;
margin-right: 5px;
display: block;

&:hover {
 color: #00FFFF;
 text-decoration: none;
}
`;

export default Link;
