import styled from '@emotion/styled';

const SemiTitle = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0;
  text-decoration: underline;
`;

export default SemiTitle;
