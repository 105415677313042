import React from 'react';
import styled from '@emotion/styled';
import Fade from 'react-reveal/Fade';
import GatsbyLogo from './GatsbyLogo';
import Logo from '../header/Logo';

const FooterStyled = styled.footer`
color: rgb(255, 255, 255);
display: flex;
flex-direction: column;
-webkit-box-pack: center;
justify-content: center;
-webkit-box-align: center;
align-items: center;
background: rgb(0, 99, 250);
flex: 0 1 auto;
padding: 4rem;
`;

const PowerdedBy = styled.div`
  display: table;
  font-size: 1rem;
`;

const Footer = () => (
  <FooterStyled>
    <Fade bottom>
      <PowerdedBy>
        { `© ${new Date().getFullYear()} by ` }
        <Logo width={25} />
        { ' ' }
        { ' Gilbert Łaskawiec.' }
      </PowerdedBy>
      <PowerdedBy>
        { 'Powered by ' }
        <GatsbyLogo />
        { ' ' }
        { ' Gatsby.' }
      </PowerdedBy>
    </Fade>
  </FooterStyled>
);

export default Footer;
