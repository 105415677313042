import styled from '@emotion/styled';

const Input = styled.input`

  width: ${props => (props.width ? props.width : 300)}px;
  height: ${props => (props.height ? props.height : 50)}px;
  border: 3px white solid;
  border-radius: 5px;
  background: transparent;
  color: white;
  font-size: 19px;
  padding-left: 15px;
  margin: 30px;
  
  &:focus {
   border-color: #00FFFF;
   outline: none;
  }
  
    &:hover {
   border-color: #00FFFF;
   outline: none;
  }
`;

export default Input;
