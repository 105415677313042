import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

const move = keyframes`
  25% {
    opacity: 1;
}
  33% {
    opacity: 1;
  transform: translateY(30px);
}
  67% {
    opacity: 1;
  transform: translateY(40px);
}
  100% {
    opacity: 0;
  transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
}
`;

const Chevron = styled.div`
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: ${move} 3s ease-out infinite;
  


&:first-of-type {
  animation: ${move} 3s ease-out 1s infinite;
}

&:nth-of-type(2) {
  animation: ${move} 3s ease-out 2s infinite;
}

&::before, &::after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #fff;
}

&::before {
  left: 0;
  transform: skew(0deg, 30deg);
  background-color: ${props => (props.isHover ? '#00FFFF' : '#FFFFFF')};
}



&::after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
  background-color: ${props => (props.isHover ? '#00FFFF' : '#FFFFFF')};
}

`;

export default Chevron;
