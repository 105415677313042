import styled from '@emotion/styled';

const Form = styled.form`
height: 100%;
padding-top: 86px;

  @media (min-width: 768px) {
    padding-top: 200px;
  }
`;

export default Form;
