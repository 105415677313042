import styled from '@emotion/styled';
import { Section } from 'react-scroll-section';

const SectionStyled = styled(Section)`
  @media (max-width: 768px) {
    ${props => (props.isIntro ? '' : 'padding-top: 86.38px;')}
  }

  overflow-x: hidden;
  text-align: center;
  min-height: 89vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: ${props => props.background}
`;

export default SectionStyled;
