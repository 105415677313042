import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TooltipWrapper from './TooltipWrapper';
import TooltipStyled from './TooltipStyled';

const Tooltip = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <TooltipWrapper>
      <span
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </span>
      <TooltipStyled isVisible={isVisible}>
        <span>{text}</span>
      </TooltipStyled>
    </TooltipWrapper>
  );
};

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
};

export default Tooltip;
