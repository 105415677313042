import React, { useContext } from 'react';
import Dropdown from './Dropdown';
import DropdownItem from './DropdownItem';
import LangSwitchWrapper from './LangSwitchWrapper';
import { LangContext } from '../../LangContextProvider';

const LangSwitch = () => {
  const { lang, setLang } = useContext(LangContext);

  const onClick = () => {
    setLang(lang === 'pl' ? 'en' : 'pl');
  };

  return (
    <LangSwitchWrapper>
      <Dropdown>
        <DropdownItem>{lang.toUpperCase()}</DropdownItem>
        <DropdownItem onClick={onClick}>
          {lang === 'pl' ? 'EN' : 'PL'}
        </DropdownItem>
      </Dropdown>
    </LangSwitchWrapper>
  );
};

export default LangSwitch;
