import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ width, style }) => (
  <svg
    style={style}
    width={width}
    height={width}
    viewBox="0 0 132.1 119.6"
  >
    <path
      fill="#FFF"
      d="M39.9,62.2v20.7c-3.3,1.2-6.4,1.8-9.4,1.8c-7.3,0-13.5-2.8-18.5-8.3C7,71,4.5,64.2,4.5,56.2
                   c0-8.6,3-15.9,9.1-21.8c6.1-5.9,13.6-8.9,22.4-8.9c4.7,0,9.2,0.9,13.5,2.8c4.3,1.9,7.7,4.4,10.2,7.6c4.1,5.3,6.2,12.9,6.2,23v19.8
                   c0,10.8-3.1,19.5-9.2,26.2c-6.1,6.7-14,10.1-23.8,10.1c-3,0-6.7-0.7-11.3-2.2V92.2c2.9,1.6,5.9,2.4,8.9,2.4c4.1,0,7.2-1.3,9.4-4
                   c2.2-2.7,3.3-6.5,3.3-11.5V56.5c0-6.3-2.6-9.5-7.7-9.5c-2.2,0-4.1,0.8-5.7,2.5c-1.6,1.7-2.4,3.6-2.4,5.8c0,2.3,0.8,4.2,2.3,5.7
                   c1.5,1.5,3.5,2.3,5.8,2.3C37,63.3,38.5,62.9,39.9,62.2z"
    />
    <path fill="#FFF" d="M101.7,4.6v82.5H79.1V4.6H101.7z" />
    <polygon fill="#FFF" points="87.6,82 127.6,41.9 111.6,26 79,58.5 79,82" />
  </svg>
);

Logo.propTypes = {
  width: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

Logo.defaultProps = {
  style: {},
};

export default Logo;
