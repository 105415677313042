import styled from '@emotion/styled';

const TopLine = styled.div`
  height: 2px;
  width: 20px;
  background: ${props => (props.isHover ? '#00FFFF' : 'white')};
  transition: all 0.2s ease;
  transform: ${props => (props.isOpen ? 'rotate(45deg)' : 'none')};
  transform-origin: top left;
  margin-bottom: 5px;
`;

export default TopLine;
