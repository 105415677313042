const selectStyles = {
  singleValue: styles => ({ ...styles, color: 'white' }),
  container: styles => ({
    ...styles,
    margin: 30,
    display: 'inline-block',
  }),
  menu: styles => ({
    ...styles,
    fontSize: 19,
    backgroundColor: 'black',
    color: 'white',
    border: '3px white solid',
    borderRadius: 5,
  }),
  control: styles => ({
    ...styles,
    backgroundColor: 'black',
    border: '3px white solid',
    width: 300,
    height: 50,
    fontSize: 19,
    borderRadius: 5,
    cursor: 'pointer',
    borderColor: '#00FFFF',
    outline: 'none',
    ':focus': {
      ...styles[':focus'],
      borderColor: '#00FFFF',
    },
    ':hover': {
      ...styles[':hover'],
      borderColor: '#00FFFF',
    },
  }),
  option: (styles, {
    isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    color: 'white',
    backgroundColor: isFocused ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'pointer',

    ':active': {
      ...styles[':active'],
      borderColor: '#00FFFF',
      backgroundColor: !isDisabled && (isSelected ? 'black' : 'black'),
      borderRadius: 5,
    },
    ':focus': {
      ...styles[':focus'],
      borderColor: '#00FFFF',
    },
    ':hover': {
      ...styles[':hover'],
      borderColor: '#00FFFF',
    },
  }),
};

export default selectStyles;
