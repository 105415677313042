import styled from '@emotion/styled';

const Text = styled.div`
  font-size: 1.6rem;
  color: ${props => props.color};
  font-weight: bold;
  text-align: center;
  line-height: 1;
  
  ${props => (props.toRight ? 'text-align: right' : '')};
  
   @media (max-width: 768px) {
   ${props => (props.marginTop ? 'margin-top: 100px' : '')};
    font-size: 40px;
    text-align: center;
  }
`;

export default Text;
