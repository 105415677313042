import styled from '@emotion/styled';

const Name = styled.div`
font-size: 30px;
font-weight: bold;
line-height: 80%;
margin-bottom: 25px;

`;

export default Name;
