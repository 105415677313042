/* eslint-disable max-len,global-require,import/no-dynamic-require */
import React, { useContext, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Col, Grid, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import ProjectsList from './ProjectsList';
import SectionStyled from '../../components/SectionStyled';
import SectionTitleWrapper from '../../components/sectionTitle/SectionTitleWrapper';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import Project from './Project';
import Name from './Name';
import Desc from './Desc';
import Link from './Link';
import Technologies from './Technologies';
import Logo from './Logo';
import GithubIcon from './GithubIcon';
import LinkIcon from './LinkIcon';
import LinksSection from './LinksSection';
import { LangContext } from '../../components/LangContextProvider';
import Tooltip from './Tooltip';
import SkillsList from '../../assets/json/skillsList';

const Projects = ({ locales }) => {
  const [isLinkIconHover, setIsLinkIconHover] = useState({});
  const [isGithubIconHover, setIsGithubIconHover] = useState({});
  const { lang } = useContext(LangContext);

  return (
    <SectionStyled
      background="rgb(0,99,250)"
      id="projects"
    >
      <Grid>
        <Fade top>
          <SectionTitleWrapper>
            <SectionTitle>
              {locales.title}
            </SectionTitle>
          </SectionTitleWrapper>
        </Fade>

        <Row center="md">
          {ProjectsList.map(project => (
            <Col key={project.name} style={{ marginBottom: '2rem' }} md={4}>
              <Fade bottom>
                <Project>
                  <Name>{project.name}</Name>
                  <Desc>{project.desc[lang]}</Desc>
                  <Technologies>
                    <Row>
                      {project.technologies.map(technology => (
                        <Col style={{ margin: 5 }}>
                          <div key={technology} style={{ display: 'inline-block' }}>
                            <Tooltip text={SkillsList.find(x => x.fileName === technology).label}>
                              <Logo
                                alt={technology}
                                src={require(`../../assets/svg/${technology}.svg`)}
                              />
                            </Tooltip>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Technologies>
                  <LinksSection>
                    {project.githubLink ? (
                      <div
                        key={project.githubLink}
                        style={{ display: 'inline-block' }}
                        onMouseEnter={() => setIsGithubIconHover({ ...isGithubIconHover, [project.githubLink]: true })}
                        onMouseLeave={() => setIsGithubIconHover({ ...isGithubIconHover, [project.githubLink]: false })}
                      >
                        <Link target="_blank" href={project.githubLink}>
                          <GithubIcon
                            color={isGithubIconHover[project.githubLink] ? '#00FFFF' : 'white'}
                            width={20}
                          />
                          {` ${locales.sourceCode}`}
                        </Link>
                      </div>
                    ) : '' }
                    {project.demoLink ? (
                      <div>
                        <div
                          key={project.demoLink}
                          style={{ display: 'inline-block' }}
                          onMouseEnter={() => setIsLinkIconHover({ ...isGithubIconHover, [project.demoLink]: true })}
                          onMouseLeave={() => setIsLinkIconHover({ ...isGithubIconHover, [project.demoLink]: false })}
                        >
                          <Link target="_blank" href={project.demoLink}>
                            <LinkIcon
                              color={isLinkIconHover[project.demoLink] ? '#00FFFF' : 'white'}
                              width={20}
                            />
                            {' Demo'}
                          </Link>
                        </div>
                      </div>
                    ) : '' }
                  </LinksSection>
                </Project>
              </Fade>
            </Col>
          ))}
        </Row>
      </Grid>
    </SectionStyled>
  );
};

Projects.propTypes = {
  locales: PropTypes.shape({
    title: PropTypes.string.isRequired,
    sourceCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default Projects;
