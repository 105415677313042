import React from 'react';
import { Global } from '@emotion/core';
import { ScrollingProvider } from 'react-scroll-section';
import emailjs from 'emailjs-com';
import SEO from '../components/seo';
import Header from '../components/header/Header';
import Intro from '../sections/Intro/Intro';
import About from '../sections/About/About';
import Skills from '../sections/Skills/Skills';
import Projects from '../sections/Projects/Projects';
import Footer from '../components/footer/Footer';
import GlobalStyles from '../GlobalStyles';
import IntroLocales from '../sections/Intro/Locales';
import AboutLocales from '../sections/About/Locales';
import SkillsLocales from '../sections/Skills/Locales';
import ProjectLocales from '../sections/Projects/Locales';
import seo from '../seo';
import 'bootstrap/dist/css/bootstrap.min.css';
import LangContextProvider, { LangContextConsumer } from '../components/LangContextProvider';
import Estimate from '../sections/Estimate/Estimate';

const Index = () => {
  emailjs.init('user_zpbfdMVAN6jQGtp52Xche');

  return (
    <>
      <SEO
        title={seo.title}
        lang={seo.lang}
        description={seo.description}
        keywords={seo.keywords}
      />
      <Global styles={GlobalStyles} />
      <LangContextProvider>
        <ScrollingProvider>
          <LangContextConsumer>
            {({ lang, setIsEstimateOpen }) => (
              <>
                <Header />
                <Estimate />
                <Intro
                  locales={IntroLocales[lang]}
                  setIsEstimateOpen={setIsEstimateOpen}
                />
                <About locales={AboutLocales[lang]} />
                <Skills locales={SkillsLocales[lang]} />
                <Projects locales={ProjectLocales[lang]} />
                <Footer />
              </>
            )}
          </LangContextConsumer>
        </ScrollingProvider>
      </LangContextProvider>
    </>
  );
};


export default Index;
