import styled from '@emotion/styled';

const MiddleLine = styled.div`
  height: 2px;
  width: 20px;
  background: ${props => (props.isHover ? '#00FFFF' : 'white')};
  transition: all 0.2s ease;
  opacity: ${props => (props.isOpen ? 0 : 1)};
  transform: ${props => (props.isOpen ? 'translateX(-16px)' : 'none')};
`;

export default MiddleLine;
