import styled from '@emotion/styled';

const Avatar = styled.img`
  border: 2px solid #fff;
  vertical-align: middle;
  width: 200px;
  height: 200px;
  margin-bottom: 1rem;
  border-radius: 50%;
`;

export default Avatar;
