import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SectionLink } from 'react-scroll-section';
import Overlay from './Overlay';
import Navigation from './Navigation';
import MenuStyled from './MenuStyled';
import Item from './Item';
import { LangContext } from '../../../LangContextProvider';

const Menu = ({ isMenuOpen, setIsMenuOpen, menuItems }) => {
  const { lang } = useContext(LangContext);

  return (
    <Overlay
      isMenuOpen={isMenuOpen}
      id="overlay"
    >
      <Navigation>
        <MenuStyled>
          {menuItems.map(menuOption => (
            <SectionLink key={menuOption.path} section={menuOption.path}>
              {({ onClick }) => (
                <Item
                  key={menuOption.path}
                  aria-hidden
                  onClick={() => { setIsMenuOpen(false); onClick(); }}
                >
                  {menuOption.label[lang]}
                </Item>
              )}
            </SectionLink>
          ))}
        </MenuStyled>
      </Navigation>
    </Overlay>
  );
};


Menu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Menu;
