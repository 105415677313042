import React from 'react';
import LangSwitch from './LangSwitch/LangSwitch';
import BurgerButton from './BurgerButton/BurgerButton';
import HeaderStyled from './HeaderStyled';

const Header = () => (
  <HeaderStyled>
    <LangSwitch />
    <BurgerButton />
  </HeaderStyled>
);

export default Header;
