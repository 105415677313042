import styled from '@emotion/styled';

const Overlay = styled.div`
overflow-x: hidden;
  position: fixed;
  background: #000000;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;
  z-index: 1;
  
   display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
  }

  ${props => (props.isMenuOpen ? `
  opacity: 1;
  visibility: visible;
  height: 100%;` : '')
}
  


    
`;

export default Overlay;
