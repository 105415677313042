import { css } from '@emotion/core';

const GlobalStyles = css`
  html,
  body {
    font-family: 'Karla', arial, helvetica, serif;
    color: white;
    background-color: rgba(0, 0, 30, 1);
  }
  
  a:hover{
    text-decoration: none;
    color: #00FFFF;
  }
  
  button:focus {
    outline: none;
  }

  @import url('https://fonts.googleapis.com/css?family=Karla');
`;

export default GlobalStyles;
