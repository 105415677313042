import styled from '@emotion/styled';

const Article = styled.article`
  box-shadow: rgba(0, 79, 159, 0.36) 0px 14px 36px;
  background-color: rgb(0, 127, 255);
  padding: 2.25rem 3.375rem;
  transition: transform 0.15s ease-out 0s, box-shadow 0.15s ease-out 0s, background-color 0.15s ease-out 0s;

  
  &:hover {
    transform: scale(1.05);
  background-color: rgb(0, 127, 255);
  }
`;

export default Article;
