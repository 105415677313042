import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import detectBrowserLanguage from 'detect-browser-language';

export const LangContext = createContext({ lang: 'en', isEstimateOpen: false });
export const LangContextConsumer = LangContext.Consumer;

const LangContextProvider = ({ children }) => {
  let initialLang = 'en';

  if (typeof window !== 'undefined') {
    initialLang = detectBrowserLanguage() === 'pl-PL' ? 'pl' : 'en';
  }
  const [lang, setLang] = useState(initialLang);
  const [isEstimateOpen, setIsEstimateOpen] = useState(false);

  return (
    <LangContext.Provider value={{
      lang,
      setLang,
      isEstimateOpen,
      setIsEstimateOpen,
    }}
    >
      {children}
    </LangContext.Provider>
  );
};

LangContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LangContextProvider;
