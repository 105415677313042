import React from 'react';
import PropTypes from 'prop-types';

const Tick = ({ size, style }) => (
  <svg
    style={style}
    width={size}
    viewBox="0 0 243 187"
    version="1.1"
  >
    <path
      fill="white"
      d="M2.80384615,102.85 C0.934615385,100.98 0,98.175 0,96.305 C0,94.435 0.934615385,91.63 2.80384615,89.76 L15.8884615,76.67 C19.6269231,72.93 25.2346154,72.93 28.9730769,76.67 L29.9076923,77.605 L81.3115385,132.77 C83.1807692,134.64 85.9846154,134.64 87.8538462,132.77 L213.092308,2.805 L214.026923,2.805 L214.026923,2.805 C217.765385,-0.935 223.373077,-0.935 227.111538,2.805 L240.196154,15.895 C243.934615,19.635 243.934615,25.245 240.196154,28.985 L240.196154,28.985 L90.6576923,184.195 C88.7884615,186.065 86.9192308,187 84.1153846,187 C81.3115385,187 79.4423077,186.065 77.5730769,184.195 L4.67307692,105.655 L2.80384615,102.85 Z"
      id="Path"
    />
  </svg>
);

Tick.propTypes = {
  size: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

Tick.defaultProps = {
  size: 70,
  style: {},
};

export default Tick;
