import styled from '@emotion/styled';

const SectionTitle = styled.span`
    font-size: 3rem;
    font-weight: 600;
    position: relative;
    cursor: default;
    text-decoration: none;
    border-bottom: 5px solid #F64C72;
    transition: all 0.4s ease 0s;

&:hover::after {
    left: 0px;
    right: auto;
    width: 100%;
}

&::after {
    content: "";
    position: absolute;
    right: 0px;
    width: 0px;
    bottom: -5px;
    height: 5px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    background: #00FFFF;
}
`;

export default SectionTitle;
