import styled from '@emotion/styled';

const Link = styled.a`
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 1.2rem;
  //background: white;
  background: transparent;
  border: 2px solid white;
  border-radius: 5px;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  
  
  
  &:hover {
  border-color: #00FFFF;
  color: #00FFFF;

  }
`;

export default Link;
