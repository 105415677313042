import React from 'react';
import Fade from 'react-reveal/Fade';
import moment from 'moment';
import {
  faEnvelopeOpen,
  faUserCircle,
  faMapMarkedAlt,
  faChild,
  faAlignCenter,
} from '@fortawesome/free-solid-svg-icons';
import { Col, Grid, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import SectionStyled from '../../components/SectionStyled';
import AvatarQuad from '../../assets/png/avatar.png';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import SemiTitle from '../../components/SemiTitle';
import SectionTitleWrapper from '../../components/sectionTitle/SectionTitleWrapper';
import Avatar from './Avatar';
import IconStyled from './IconStyled';
import Paragraph from '../../components/Paragraph';

const carrierStart = moment('2017-7-1');
const experienceInMonths = (moment().diff(carrierStart, 'months').toString());
const experienceInYears = Math.floor(experienceInMonths / 12);
const experienceInMonthsWithoutYears = experienceInMonths - experienceInYears * 12;

const About = ({ locales }) => (
  <SectionStyled
    background="linear-gradient(180deg, rgba(0,0,54,1) 0%, rgba(8,0,117,1) 100%)"
    id="about"
  >
    <Grid>
      <Fade top>
        <SectionTitleWrapper>
          <SectionTitle>
            {locales.aboutMe}
          </SectionTitle>
        </SectionTitleWrapper>
      </Fade>
      <Row>
        <Col style={{ margin: 'auto' }} md={3}>
          <Fade left>
            <Avatar
              alt="avatar"
              src={AvatarQuad}
            />
          </Fade>
        </Col>

        <Col
          md={4}
          style={{ textAlign: 'left', margin: 'auto' }}
        >
          <Fade right>
            <SemiTitle>
              <IconStyled icon={faUserCircle} />
              {locales.fullName}
            </SemiTitle>
            <Paragraph>Gilbert Łaskawiec</Paragraph>
            <SemiTitle>
              <IconStyled icon={faMapMarkedAlt} />
              {locales.localization}
            </SemiTitle>
            <Paragraph>Katowice, Poland</Paragraph>
            <SemiTitle>
              <IconStyled icon={faChild} />
              {locales.experience}
            </SemiTitle>
            <Paragraph>
              {`${experienceInYears} ${locales.years} ${experienceInMonthsWithoutYears} ${locales.experienceText}`}
            </Paragraph>
            <SemiTitle>
              <IconStyled icon={faEnvelopeOpen} />
              {locales.contact}
            </SemiTitle>
            <Paragraph>gilbertlaskawiec@gmail.com</Paragraph>
          </Fade>
        </Col>
        <Col md={5} style={{ textAlign: 'left', margin: 'auto' }}>
          <Fade right>
            <SemiTitle>
              <IconStyled icon={faAlignCenter} />
              {locales.overview}
            </SemiTitle>
            <Paragraph>
              {locales.overviewText}
            </Paragraph>
          </Fade>
        </Col>
      </Row>
    </Grid>
  </SectionStyled>
);

About.propTypes = {
  locales: PropTypes.shape({
    aboutMe: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    localization: PropTypes.string.isRequired,
    experience: PropTypes.string.isRequired,
    experienceText: PropTypes.string.isRequired,
    years: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    overview: PropTypes.string.isRequired,
    overviewText: PropTypes.string.isRequired,
  }).isRequired,
};

export default About;
