import styled from '@emotion/styled';

const Wrapper = styled.div`
  height: 1.5rem;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 1.5rem;
  top: 2rem;
`;

export default Wrapper;
