import styled from '@emotion/styled';

const HeaderStyled = styled.header`
  z-index: 10;
  color: white;
  display: flex;
  position: fixed;
  right: 0;
  width: 100%;
  justify-content: flex-end;
  justify-items: center;
  background-color: transparent;
  height: 86px;
  
  @media (max-width: 768px) {
    background-color: rgba(0,0,30,1);
  }
`;

export default HeaderStyled;
