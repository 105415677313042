import React, { useState, useContext } from 'react';
import Wrapper from './Wrapper';
import Menu from './Menu/Menu';
import TopLine from './TopLine';
import MiddleLine from './MiddleLine';
import BottomLine from './BottomLine';
import MenuItems from './Menu/MenuItems';
import { LangContext } from '../../LangContextProvider';

const BurgerButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const { isEstimateOpen, setIsEstimateOpen } = useContext(LangContext);

  const onBurgerButtonClick = () => {
    if (!isEstimateOpen) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(false);
      setIsEstimateOpen(false);
    }
  };


  return (
    <>
      <Menu
        isMenuOpen={isOpen}
        setIsMenuOpen={setIsOpen}
        menuItems={MenuItems}
      />
      <Wrapper
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={onBurgerButtonClick}
      >
        <TopLine isOpen={isOpen || isEstimateOpen} isHover={isHover} />
        <MiddleLine isOpen={isOpen || isEstimateOpen} isHover={isHover} />
        <BottomLine isOpen={isOpen || isEstimateOpen} isHover={isHover} />
      </Wrapper>
    </>
  );
};

export default BurgerButton;
