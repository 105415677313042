import styled from '@emotion/styled';

const DropdownItem = styled.li`
color: #00001E;
 cursor: pointer;
  display: block;
  padding: 0px 9px;
  line-height: 40px;
  border-top: 1px solid #EEE;

  &:hover{
    background-color: #00FFFF;
  }
`;

export default DropdownItem;
