import styled from '@emotion/styled';

const Name = styled.label`
  display: block;
  max-width: 100px;
  text-align: center;
  line-height: 1;
  margin: 0;
`;

export default Name;
