/* eslint-disable import/no-dynamic-require,global-require */
import React from 'react';
import Fade from 'react-reveal/Fade';
import { Col, Grid, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import SkillsList from '../../assets/json/skillsList';
import SectionStyled from '../../components/SectionStyled';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import SectionTitleWrapper from '../../components/sectionTitle/SectionTitleWrapper';
import SkillIcon from './SkillIcon';
import Paragraph from '../../components/Paragraph';
import Name from './Name';

const Skills = ({ locales }) => (
  <SectionStyled
    background="linear-gradient(180deg, rgba(8,0,117,1) 0%, rgb(0,99,250) 100%)"
    id="skills"
  >
    <Grid>
      <Fade top>
        <SectionTitleWrapper>
          <SectionTitle>
            {locales.skills}
          </SectionTitle>
        </SectionTitleWrapper>
      </Fade>
      <Row center="md">
        <Col style={{ margin: 'auto', marginBottom: 50 }} md={5}>
          <Fade left>
            <>
              {SkillsList.map((icon => (
                <div key={icon.label} style={{ marginRight: 20, display: 'inline-block' }}>
                  <SkillIcon
                    key={icon.label}
                    width={icon.width}
                    src={require(`../../assets/svg/${icon.fileName}.svg`)}
                  />
                  <Name>{icon.label}</Name>
                </div>
              )))}
            </>
          </Fade>
        </Col>
        <Col md={5} style={{ margin: 'auto 0', textAlign: 'left' }}>
          <Fade right>
            <Paragraph>{locales.text1}</Paragraph>
            <Paragraph>{locales.text2}</Paragraph>
            <Paragraph>{locales.text3}</Paragraph>
            <Paragraph>{locales.text4}</Paragraph>
          </Fade>
        </Col>
      </Row>
    </Grid>
  </SectionStyled>
);

Skills.propTypes = {
  locales: PropTypes.shape({
    text1: PropTypes.string.isRequired,
    skills: PropTypes.string.isRequired,
    text2: PropTypes.string.isRequired,
    text3: PropTypes.string.isRequired,
    text4: PropTypes.string.isRequired,
  }).isRequired,
};

export default Skills;
