import styled from '@emotion/styled';

const BottomLine = styled.div`
  height: 2px;
  width: 20px;
  background: ${props => (props.isHover ? '#00FFFF' : 'white')};
  transition: all 0.2s ease;    
  transform: ${props => (props.isOpen ? 'translateX(-1px) rotate(-45deg)' : 'none')};
  transform-origin: top left;
  margin-top: 5px;
`;

export default BottomLine;
