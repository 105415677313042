import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

const move = keyframes`
  0% {
    opacity: 0;
    transform:translate(-50%, -20%);
  }
  
  100% {
    opacity: 1;
    transform:translate(-50%, 0);
}
`;

const TooltipStyled = styled.div`
  //animation: ${move} 0.5s;
  left:50%;
  transform:translate(-50%, 0);
  padding: 3px 10px;
  color: black;
  background-color: white;
  border-radius: 5px;
  position:absolute;
  z-index:99999999;
  display: ${props => (props.isVisible ? 'block' : 'none')};
  margin-top: 5px;

`;

export default TooltipStyled;
